@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&display=swap');

body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  font-size: 16px;
}

a {
  text-decoration: initial;
  color: #2e4063;
}
a:hover {
  cursor: pointer;
  text-decoration: initial;
  opacity: 0.8;
}

* {
  /* outline: 1px solid #dbdbdb; */
}

.draweritem > div > div > span {
  color: #2e4063;
  font-weight: 500;
}

.draweritem > div > div > svg {
  color: #2e4063;
  min-width: initial;
  margin-right: 16px;
  font-size: 25px;
}

.activedraweritem > div > div > span {
  color: #2e4063;
  font-weight: 500;
}

.activedraweritem > div > div > svg {
  color: #2e4063;
  min-width: initial;
  margin-right: 16px;
  font-size: 25px;
}

.activedraweritem > div {
  background: #ededf6;
}
